import { render, staticRenderFns } from "./DocViewer.vue?vue&type=template&id=48af59a4&scoped=true"
import script from "./DocViewer.vue?vue&type=script&lang=js"
export * from "./DocViewer.vue?vue&type=script&lang=js"
import style0 from "./DocViewer.vue?vue&type=style&index=0&id=48af59a4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48af59a4",
  null
  
)

export default component.exports